// ES6 module syntax
import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
 en:{
   'language':"en",
  'Dom':"Sun",
  'Lun':"Mon", 'Mar':"Tue", 'Mié':"Wed", 'Jue':"Thu", 'Vie':"Fri", 'Sáb':"Sat",
  "Información adicional": "Additional information",
  "Mis direcciones":"My addresses",
  "Registro":"Create an account",
  "OTP": "OTP",
  "Teléfono":"Phone:",
  "Selecciona tu método de pago":"Select payment method",
  "Promociones": "Promotions",
  "Ingresar":"Login",
  "closed_today":"Close",
  "Bienvenido": "Welcome",
  "Bienvenido de regreso": "Welcome back",
  "Crea tu cuenta para continuar":"Create an account to continue",
  "Ingresa tu cuenta para continuar":"Enter your credentials to continue",
  "Ver pedido": "View basket",
  "Inicio de sesión exitoso":"Successful login",
  "Error de inicio de sesión":"Login error",
  "Esperando a que el negocio acepte su pedido": "Waiting for the business to accept your order",
  "El negocio está preparando su producto.": "The business is preparing its product.",
  " está en camino para recoger su pedido":" is on its way to pick up your order",
  " llegó al negocio":" reached business",
  " ha recogido tu pedido":" has picked up your order",
  "Su orden ha sido completada":"Your order has been completed",
  "Su pedido ha sido cancelado": "Your order has been canceled",
  "Iniciar sesión":"Log in",
  "Registrarse": "Register",
  "Perfil": "Profile",
  "Agregar $":"Add $",
  "Mis Pedidos": "My Orders",
  "Cerrar sesión": "Log out",
  "Hogar": "Home",
  "Su cupón ha sido agregado con éxito.": "Your coupon has been successfully added.",
  "Añade al menos ":"Add atleast ",
  " productos promocionales.": " promotional products.",
  "Los productos máximos deben ser $":"Max products should be $",
  "Los productos mínimos deben ser $": "Minimum products must be $",
  "Este cupón se ha utilizado recientemente, inténtalo de nuevo más tarde.":"This coupon has been used recently, please try again later.",
  " Costo de envío: ":" Delivery fee ",
  "envio":"Delivery",
  " Descuento: ": " Discount: ",
  "Seleccionar mi ubicación": "Select my location",
  "Buscar producto": "Search product",
  "ID del pedido: ": "Order ID: ",
  "Obligatorio" :"Mandatory",
  "Selecciona al menos ": "Select at least ",
  "Selecciona de ": "Select from ",
  ".Hasta ": ".Till ",
  "Selecciona hasta ": "Select up to ",
  "Email": "Email",
  "Contraseña":"Password",
  "o":"or",
  "Nombre completo": "Full name",
  "Número de teléfono":"Phone number",
  "Datos correctos":"Correct data",
  "Existe un error en el registro":"There is an error in the registration",
  "Crear cuenta": "Create account",
  "Cambiar número Éxito":"Change number Success",
  "Reenviar":"Resend",
  "Mensaje enviado correctamente al número anterior":"Message successfully sent to previous number",
  "Has ingresado correctamente":"You have entered correctly",
  "Envío OTP con éxito":"OTP Send Successfully",
  "El código ingresado no es correcto, intenta nuevamente":"The code entered is not correct, please try again",
  "Calificación:":"Rating:",
  "Correo:":"Email:",
  "Dirección:":"Address:",
  "Selecciona tu ubicación o ingrese una nueva dirección":"Select your location or enter a new address",
  "Ingrese la dirección de entrega":"Enter the delivery address",
  "Detalles adicionales":"Additional Details",
  "mi hogar":"my home",
  "mi Trabajo":"my work",
  "otra dirección":"a visit address",
  "Trabajo":"Work",
  "Visita":"Visit",
  "Cancelar":"Cancel",
  "Guardar":"Save",
  "Usar mi ubicación actual":"Use my current location",
  "Agregar nueva tarjeta":"Add new card",
  "Pago en efectivo":"Cash payment",
  "Tarjeta a la entrega":"Card on delivery",
  ". Ver detalles":". See details",
  ". Menos detalles":". Less details",
  "Comprar ":"Buy ",
  " productos y pago ":" products and pay for ",
  " en productos seleccionados":" on selected products",
  "Productos promocionados:":"Promoted Products:",
  "Se eliminará la tarifa de envío":"Delivery fee will be removed",
  "El número mínimo de productos debe ser $":"The minimum total of products must be $",
  "El número máximo de productos debe ser $":"The maximum total of products must be $",
  "Descuento $":"Discount $",
  "Obtener $":"Get $",
  " al final del día en Wallet":" at the end of the day in Wallet",
  "Obtener ":"Get ",
  "% de cashback sobre el total de tus productos en Wallet":"% of cashback on the total of your products in Wallet",
  "% de descuento en todos sus productos.":"% discount on all your products.",
  "Consigue un descuento mínimo de $":"Get a minimum discount of $",
  "Sube hasta $":"Up to $",
  " descuento":" discount",
  "Aplicar":"Apply",
  "Lo sentimos, no hay descuentos disponibles en este momento.":"Sorry, there are no discounts available at this time.",
  "Entrega de:":"Delivery",
  "Recoger":"Pickup in",
  "Recoger en:":"Pick up in",
  "min aprox":"min approx",
  "costo de envío: $":"Delivery fee $",
  "Distancia":"Distance",
  " ¡Promociones! ":" Promotions! ",
  "Para recoger tu pedido debes dirigirte a:":"To collect your order you must go to:",
  "Entrega a domicilio":"Delivery",
  "Recoger en tienda":"Pickup",
  "Menú":"Menu",
  "Información":"Information",
  "Informaciónshort":"Info",
  "Tu pedido":"Your order",
  "entrega":"Deliver",
  "Entregar a ":"Deliver to ",
  "Agregar instrucciones de entrega":"Add delivery instructions",
  "Para recoger tu pedido debes dirigirte a":"To pick up your order you must go to",
  "Ver el mapa":"View map",
  "Sí enviar cubiertos":"The restaurant will add cutlery for you",
  "No envíar cubiertos":"Only request cutlery when you need it",
  "Agregar instrucciones de preparación":"Order Notes",
  "Selecciona un cupón de descuento":"Select a discount coupon",
  "Borrar":"Remove",
  "Agregar propina para el repartidor":"Add tip for driver",
  "¡Las entregas solo son posible gracias a ellos!":"Deliveries are only possible thanks to them!",
  "Todos los productos":"Subtotal",
  "Tarifa de servicio":"Service fee",
  "Costo de envío":"Delivery fee",
  "Descuento": "Discount",
  "Propina":"Tip",
  "Método de pago":"Payment method",
  "Total":"Total",
  "Elige tu método de pago ":"Choose your payment method",
  "Esperando a que el negocio acepte el pedido":"Waiting for the business to accept the order",
  "Nuevo pedido de ":"New order from ",
  "Nuevo orden":"New order",
  "Pedido realizado con éxito":"Order made successfully",
  "Algo salió mal al crear el pedido":"Something went wrong when creating the order",
  "El negocio está cerrado":"Business is closed",
  "Pagar pedido ":"Pay and order",
  "Ver mapa":"View map",
  "Hacer pedido ":"Place order",
  "Detalles del pedido":"Order details",
  "Cancelar pedido":"Cancel order",
  "Pedido número":"Order ID",
  "ID del pedido":"Order ID",
  "Entrega estimada": "Estimate delivery",
  "Recogida estimada":"Estimate pickup",
  "Ver más":"See more",
  "Te avisaremos una vez que el negocio acepte tu pedido":"We will notify you once the business accepts your order",
  "El negocio está preparando tu pedido":"The business is preparing your order",
  "Tu pedido está siendo preparado":"Your order is being prepared",
  " está en camino para recoger tu pedido":" is on its way to pick up your order",
  " llegó al negocio":" reached the business",
  " ha llegado al negocio y recogerá tu pedido":" has arrived at the business and will pick up your order",
  "Tu pedido ha sido entregado":"Your order has been delivered",
  "Tu pedido ha sido cancelado":"Your order has been canceled",
  "Tu ubicación":"Your location",
  "Nivel: ":"Level: ",
  "Dirección de entrega":"Delivery address",
  "Tarjeta contra entrega":"Card on delivery",
  "Efectivo":"Cash",
  "Tarjeta":"Card",
  "Día y hora de su pedido":"Date and time of your order",
  "Enviar cubiertos":"Send cutlery",
  "Sí":"Yes",
  "No":"No",
  "Detalles del pedido:":"Order details:",
  "Total parcial":"Subtotal",
  "Pagado con ":"Paid with ",
  "tarjeta":"card",
  "Pagar en efectivo":"Pay cash",
  "Regresar":"Back",
  "Nombre":"Name",
  "Teléfono":"Phone:",
  "Perfil actualizado correctamente":"Profile updated successfully",
  "Por favor ingresa de nuevo para actualizar tu contraseña":"Please login again to update your password",
  "Algo salió mal":"Something went wrong",
  "Actualizar Perfil":"Update profile",
  "Ahora puedes cambiar tu contraseña":"Now you can change your password",
  "No puedo iniciar sesión":"Cant login",
  "No existen pedidos para mostrar":"There are no orders to display",
  "Solicitar ID":"Order ID",
  "Tu orden ha sido entregada":"Your order has been delivered",
  "Pendiente":"Pending",
  "Tarjeta con terminación ":"Card with termination ",
  "Agregar tarjeta":"Insert card details",
  "Número de tarjeta":"Card number",
  "Nombre de la titular":"Card holder name",
  "Ahora":"Now",
  " Hoy, ":" Today, ",
  " Mañana, ":" Tomorrow, ",
  "hoy":"today",
  "mańana":"tomorrow",
  "Guardar tarjeta":"Save card",
  "Programar tu entrega":"Schedule your delivery",
  "Tu pedido llegará entre en lapso seleccionado. Faltando 40 minutos para su entrega, tu pedido no podrá ser cancelado":"Your order will arrive within the selected period. With 40 minutes left for delivery, your order cannot be canceled",
  "Programar y avanzar":"Preorder now",
  "El banco ha rechazado la transacción. Crea e ingresa aquí una tarjeta digital para realizar compras más seguras":"The bank has declined the transaction. Create and enter a digital card here to make more secure purchases",
  "Los datos de la tarjeta son incorrectos. Verifíquelos para continuar.":"The card details are incorrect. Please verify them to continue.",
  "Algo salió mal al crear el pago":"Something went wrong creating the payment",
  "Hubo un error al confirmar el pago. Detalles: ":"There was an error confirming the payment. Details: ",
  "Hubo un error al confirmar el pago. Vuelve a intentarlo con otra tarjeta":"There was an error confirming the payment. Please try again with another card",
  "El pago aún se encuentra en estado de \"procesamiento\". Esto es inusual por favor contáctenos":"Payment is still in \" processing \" status . This is unusual please contact us",
  "Lo sentimos, no tenemos productos disponibles para comprar en este momento, intenta en otra hora":"Sorry, we don't have products available for purchase at this moment, please try another time",
  "allergyText1": "Enter your food allergies and intolerances on how you want to receive your food. These comments will be seen by the restaurant when preparing your order.",
  "allergyHello": "Hello ",
  "profileAllergy": "Food Preferences / Allergies",
  "allergyAdd": "Add Allergy",
  "allergy": "Allergies",
  "ckeckout_allergy_tittle": "Enable your allergies",
  "ckeckout_allergy_descr": "¡Our staff will see this so this so we can take care of you!",
  "edit_allergy": "Edit allergies",
  "allergy_holder": "Add your allergies here",
  "my_allergies_list_tittle": "My active allergies list",
  "save": "Save",
  "Agotado":"Sold out",
  "Restablecer instrucciones enviadas al correo electrónico ingresado":"Reset instructions send to entered email",
  "No puedo encontrar el correo electrónico o algo salió mal.":"Cant find email or something went wrong.",
  "Por favor ingrese un correo electrónico válido primero":"Please enter a valid email first",
  "profileMyCard":"My Cards",
  "Enviar":"Send",
  "Ingrese OTP una vez que reciba":"Enter OTP once you receive",
  "Editar teléfono":"Edit phone",
  "cardData241": "Card with termination ",
  "¿Olvidaste tu contraseña?":"Forgot your password?",
  "Alergias inactivas":"Allergies disabled for this order",
  "Alergias activadas":"Allergies activated for this order",
  "allergyBannerTitle": "Learn about allergies",
  "allergyBannerSubTitle": "and food intolerances",
  "allergyTerms1": "What can I eat?",
  "allergyTerms2": "We created this profile to promote a safe intake of our users who have some type of allergy or food intolerance, since 25% of the country's population suffers from it. In this way we intend to help you obtain a safe purchase prioritizing and highlighting your allergies and food intolerances in all your orders without having to put them in each of your orders. If you don't know about this topic, you can read until the end and learn its importance.",
  "allergyTerms3": "What is a food allergy?",
  "allergyTerms4": "Food allergy occurs when the person reacts with symptoms to contact, ingestion or inhalation of food proteins, causing an immune reaction of the body to that external substance that enters it and manifests itself clinically through different signs and symptoms, milder or more severe, depending on the affected organ. A food intolerance does not arise at the time of ingestion, it can take hours or days and will only cause discomfort, no fatal risk such as those that an allergy can cause.",
  "allergyTerms5": "What reaction can an allergen cause?",
  "allergyTerms6": "Food allergy reactions vary greatly depending on the person affected. Some of these reactions can be very mild and affect only one part or system of the body. Others can be more serious and affect more than one body system. Reactions may occur within the first few minutes or take several hours to appear after exposure to the food allergen.",
  "allergyTerms7": "The symptoms or clinical manifestations will vary according to the organ or system of the affected organism:",
  "allergyTerms8": "Respiratory System: Persistent cough, wheezing, shortness of breath (choking, difficulty swallowing, voice change, and rhinitis.",
  "allergyTerms9": "Skin system (frequent): Urticaria, hives (beans). Itching and tightness in the palate and throat. Inflammation of the lips and eyelids. Conjunctivitis.",
  "allergyTerms10": "Digestive System: Vomiting, nausea, diarrhea, acute abdominal pain.",
  "allergyTerms11": "Nervous System: Unconsciousness.",
  "allergyTerms12": "Cardiovascular System: Dizziness, hypotension, fainting, cyanosis.",
  "allergyTerms13": "Anaphylaxis: Severe allergic reaction of rapid onset and life-threatening, after contact with the allergen, and when 2 or more organs or body systems are affected.",
  "allergyTerms14": "The most serious consequences are: airway obstruction (laryngeal edema) and cardiorespiratory arrest due to bronchospasm and/or vascular collapse. The most frequent causes include food, drugs and insect bites (bees and wasps).",
  "allergyTerms15": "There are more than 120 foods described as causing food allergies, among the most common are: ",
  "allergyTerms16": "- Cereals containing gluten.",
  "allergyTerms17": "- Crustaceans and crustacean-based products.",
  "allergyTerms18": "- Eggs and egg-based products.",
  "allergyTerms19": "- Fish and fish-based products.",
  "allergyTerms20": "- Soy and soy-based products.",
  "allergyTerms21": "- Milk and its derivatives (including lactose).",
  "allergyTerms22": "- Nuts and derived products. (peanuts, walnuts, etc.)",
  "allergyTerms23": "- Celery and derived products.",
  "allergyTerms24": "- Mustard and derived products.",
  "allergyTerms25": "- Sesame seeds.",
  "allergyTerms26": "- Sulfur dioxide and sulfite.",
  "allergyTerms27": "- Legumes ",
  "allergyTerms28": "- Molluscs and mollusc-based products.",
  "allergyTerms29": "Now you know about this topic, we invite you to create your profile in a conscious way and if you are not within this 25% of the population, to make them aware in case they need your help at any time. ",
  "begin": "Begin",
  "No hay una ubicación de entrega seleccionada o la ubicación de entrega seleccionada no es reparable":"There's no delivery location selected or we don't offer delivery service on that area",
  "Continuar":"Next",
  "Ingresar dirección":"Add address",
  "Por favor ingresa tu dirección":"Add your address",
  "Añadir más productos":"Add more products",
  "La compra mínima debe ser de $":"Minimum purchase should be $",
  "Gratis":"Free",
  "Creando orden, no cierre ni actualice la ventana":"Creating order, Do not close or refresh the window",
  "Buscando direcciones":"Searching for addresses...",
  "Su pedido ha sido cancelado con éxito.":"Your order has been cancelled successfully."
 },
 es: {
   'language':"es",
    'Dom':"Dom",
    'Lun':"Lun", 'Mar':"Mar", 'Mié':"Mié", 'Jue':"Jue", 'Vie':"Vie", 'Sáb':"Sáb",
    "Información adicional": "Información adicional",
    "Mis direcciones":"Mis direcciones",
    "Registro":"Crear una cuenta",
    "OTP": "OTP",
    "closed_today":"Cerrado",
    "Selecciona tu método de pago":"Selecciona tu método de pago",
    "Promociones": "Promociones",
    "Ingresar":"Ingresar",
    "Ver pedido": "Ver pedido",
    "Recoger":"Recoger en",
    "Inicio de sesión exitoso":"Inicio de sesión exitoso",
    "Error de inicio de sesión":"Error de inicio de sesión",
    "Esperando a que el negocio acepte su pedido": "Esperando a que el negocio acepte su pedido",
    "El negocio está preparando su producto.": "El negocio está preparando su producto.",
    " está en camino para recoger su pedido":" está en camino para recoger su pedido",
    " llegó al negocio":" llegó al negocio",
    " ha recogido tu pedido":" ha recogido tu pedido",
    "Su orden ha sido completada":"Su orden ha sido completada",
    "Su pedido ha sido cancelado": "Su pedido ha sido cancelado",
    "Iniciar sesión":"Iniciar sesión",
    "Registrarse": "Registrarse",
    "Perfil": "Perfil",
    "Mis Pedidos": "Mis Pedidos",
    "Cerrar sesión": "Cerrar sesión",
    "Hogar": "Hogar",
    "Agregar $":"Agregar $",
    "Su cupón ha sido agregado con éxito.": "Su cupón ha sido agregado con éxito.",
    "Añade al menos ":"Añade al menos ",
    " productos promocionales.": " productos promocionales.",
    "Los productos máximos deben ser $":"Los productos máximos deben ser $",
    "Los productos mínimos deben ser $": "Los productos mínimos deben ser $",
    "Este cupón se ha utilizado recientemente, inténtalo de nuevo más tarde.":"Este cupón se ha utilizado recientemente, inténtalo de nuevo más tarde.",
    " Costo de envío: ":" Costo de envío ",
    " Descuento: ": " Descuento: ",
    "Seleccionar mi ubicación": "Seleccionar mi ubicación",
    "Buscar producto": "Buscar producto",
    "ID del pedido: ": "ID del pedido: ",
    "Obligatorio" :"Obligatorio",
    "Selecciona al menos ": "Selecciona al menos ",
    "Selecciona de ": "Selecciona de ",
    ".Hasta ": ".Hasta ",
    "Selecciona hasta ": "Selecciona hasta ",
    "Email": "Email",
    "Contraseña":"Contraseña",
    "o":"o",
    "entrega":"Entrega",
    "Nombre completo": "Nombre completo",
    "Número de teléfono":"Número de teléfono",
    "Datos correctos":"Datos correctos",
    "Existe un error en el registro":"Existe un error en el registro",
    "Crear cuenta": "Crear cuenta",
    "Cambiar número Éxito":"Cambiar número Éxito",
    "Reenviar":"Reenviar",
    "Mensaje enviado correctamente al número anterior":"Mensaje enviado correctamente al número anterior",
    "Has ingresado correctamente":"Has ingresado correctamente",
    "El código ingresado no es correcto, intenta nuevamente":"El código ingresado no es correcto, intenta nuevamente",
    "Calificación:":"Calificación:",
    "Correo:":"Correo:",
    "Guardar tarjeta":"Guardar tarjeta",
    "Dirección:":"Dirección:",
    "Selecciona tu ubicación o ingrese una nueva dirección":"Selecciona tu ubicación o ingrese una nueva dirección",
    "Ingrese la dirección de entrega":"Ingrese la dirección de entrega",
    "Detalles adicionales":"Detalles adicionales",
    "mi hogar":"mi hogar",
    "mi Trabajo":"mi trabajo",
    "Envío OTP con éxito":"Envío OTP con éxito",
    "otra dirección":"mi dirección de visita",
    "Trabajo":"Trabajo",
    "Visita":"Visita",
    "Cancelar":"Cancelar",
    "Guardar":"Guardar",
    "Usar mi ubicación actual":"Usar mi ubicación actual",
    "Agregar nueva tarjeta":"Agregar nueva tarjeta",
    "Pago en efectivo":"Pago en efectivo",
    "Tarjeta a la entrega":"Tarjeta a la entrega",
    ". Ver detalles":". Ver detalles",
    ". Menos detalles":". Menos detalles",
    "Comprar ":"Comprar ",
    " productos y pago ":" productos y pago ",
    " en productos seleccionados":" en productos seleccionados",
    "Productos promocionados:":"Productos promocionados:",
    "Se eliminará la tarifa de envío":"Se eliminará la tarifa de envío",
    "El número mínimo de productos debe ser $":"El número mínimo de productos debe ser $",
    "El número máximo de productos debe ser $":"El número máximo de productos debe ser $",
    "Descuento $":"Descuento $",
    "Obtener $":"Obtener $",
    " al final del día en Wallet":" al final del día en Wallet",
    "Obtener ":"Obtener ",
    "% de cashback sobre el total de tus productos en Wallet":"% de cashback sobre el total de tus productos en Wallet",
    "% de descuento en todos sus productos.":"% de descuento en todos sus productos.",
    "Consigue un descuento mínimo de $":"Consigue un descuento mínimo de $",
    "Sube hasta $":"Sube hasta $",
    " descuento":" descuento",
    "Aplicar":"Aplicar",
    "Lo sentimos, no hay descuentos disponibles en este momento.":"Lo sentimos, no hay descuentos disponibles en este momento.",
    "Entrega de:":"Entrega de",
    "Recoger en:":"Recoger en",
    "min aprox":"min aprox",
    "costo de envío: $":"Costo de envío $",
    "Distancia":"Distancia",
    " ¡Promociones! ":" ¡Promociones! ",
    "Para recoger tu pedido debes dirigirte a:":"Para recoger tu pedido debes dirigirte a:",
    "Entrega a domicilio":"Entrega a domicilio",
    "Recoger en tienda":"Recoger en tienda",
    "Menú":"Menú",
    "Información":"Información",
    "Informaciónshort":"Info",
    "Tu pedido":"Tu pedido",
    "Entregar a ":"Entregar a ",
    "Agregar instrucciones de entrega":"Agregar instrucciones de entrega",
    "Para recoger tu pedido debes dirigirte a":"Para recoger tu pedido debes dirigirte a",
    "Ver el mapa":"Ver el mapa",
    "Sí enviar cubiertos":"Sí enviar cubiertos",
    "No envíar cubiertos":"No envíar cubiertos",
    "Agregar instrucciones de preparación":"Agregar instrucciones de preparación",
    "Selecciona un cupón de descuento":"Selecciona un cupón de descuento",
    "Borrar":"Borrar",
    "Agregar propina para el repartidor":"Agregar propina para el repartidor",
    "¡Las entregas solo son posible gracias a ellos!":"¡Las entregas solo son posible gracias a ellos!",
    "Todos los productos":"Todos los productos",
    "Tarifa de servicio":"Tarifa de servicio",
    "Costo de envío":"Costo de envío",
    "Descuento": "Descuento",
    "Propina":"Propina",
    "Método de pago":"Método de pago",
    "Total":"Total",
    "Teléfono":"Teléfono:",
    "Elige tu método de pago ":"Elige tu método de pago ",
    "Esperando a que el negocio acepte el pedido":"Esperando a que el negocio acepte el pedido",
    "Nuevo pedido de ":"Nuevo pedido de ",
    "Nuevo orden":"Nuevo orden",
    "Pedido realizado con éxito":"Pedido realizado con éxito",
    "Algo salió mal al crear el pedido":"Algo salió mal al crear el pedido",
    "El negocio está cerrado":"El negocio está cerrado",
    "Pagar pedido ":"Pagar pedido ",
    "Hacer pedido ":"Hacer pedido ",
    "Detalles del pedido":"Detalles del pedido",
    "Cancelar pedido":"Cancelar pedido",
    "Pedido número":"Pedido número",
    "ID del pedido":"ID del pedido",
    "Entrega estimada": "Entrega estimada",
    "Recogida estimada":"Recogida estimada",
    "Ver más":"Ver más",
    "Te avisaremos una vez que el negocio acepte tu pedido":"Te avisaremos una vez que el negocio acepte tu pedido",
    "El negocio está preparando tu pedido":"El negocio está preparando tu pedido",
    "Tu pedido está siendo preparado":"Tu pedido está siendo preparado",
    " está en camino para recoger tu pedido":" está en camino para recoger tu pedido",
    " llegó al negocio":" llegó al negocio",
    " ha llegado al negocio y recogerá tu pedido":" ha llegado al negocio y recogerá tu pedido",
    "Tu pedido ha sido entregado":"Tu pedido ha sido entregado",
    "Tu pedido ha sido cancelado":"Tu pedido ha sido cancelado",
    "Tu ubicación":"Tu ubicación",
    "Nivel: ":"Nivel: ",
    "Dirección de entrega":"Dirección de entrega",
    "Tarjeta contra entrega":"Tarjeta contra entrega",
    "Efectivo":"Efectivo",
    "Tarjeta":"Tarjeta",
    "Día y hora de su pedido":"Día y hora de su pedido",
    "Enviar cubiertos":"Enviar cubiertos",
    "Sí":"Sí",
    "No":"No",
    "Detalles del pedido:":"Detalles del pedido:",
    "Total parcial":"Total parcial",
    "Pagado con ":"Pagado con ",
    "tarjeta":"tarjeta",
    "Pagar en efectivo":"Pagar en efectivo",
    "Regresar":"Regresar",
    "Nombre":"Nombre",
    "Perfil actualizado correctamente":"Perfil actualizado correctamente",
    "Por favor ingresa de nuevo para actualizar tu contraseña":"Por favor ingresa de nuevo para actualizar tu contraseña",
    "Algo salió mal":"Algo salió mal",
    "Actualizar Perfil":"Actualizar Perfil",
    "Ahora puedes cambiar tu contraseña":"Ahora puedes cambiar tu contraseña",
    "No puedo iniciar sesión":"No puedo iniciar sesión",
    "No existen pedidos para mostrar":"No existen pedidos para mostrar",
    "Solicitar ID":"Solicitar ID",
    "Tu orden ha sido entregada":"Tu orden ha sido entregada",
    "Pendiente":"Pendiente",
    "Ver mapa":"Ver mapa",
    "Tarjeta con terminación ":"Tarjeta con terminación ",
    "Agregar tarjeta":"Agregar tarjeta",
    "Número de tarjeta":"Número de tarjeta",
    "Nombre de la titular":"Nombre de la titular",
    "El banco ha rechazado la transacción. Crea e ingresa aquí una tarjeta digital para realizar compras más seguras":"El banco ha rechazado la transacción. Crea e ingresa aquí una tarjeta digital para realizar compras más seguras",
    "Los datos de la tarjeta son incorrectos. Verifíquelos para continuar.":"Los datos de la tarjeta son incorrectos. Verifíquelos para continuar.",
    "Algo salió mal al crear el pago":"Algo salió mal al crear el pago",
    "Ahora":"Ahora",
    " Hoy, ":" Hoy, ",
    " Mañana, ":" Mañana, ",
    "hoy":"hoy",
    "mańana":"mañana",
    "envio":"Envío",
    "Programar tu entrega":"Programar tu entrega",
    "Tu pedido llegará entre en lapso seleccionado. Faltando 40 minutos para su entrega, tu pedido no podrá ser cancelado":"Tu pedido llegará entre en lapso seleccionado. Faltando 40 minutos para tu entrega, el pedido no podrá ser cancelado",
    "Programar y avanzar":"Programar y avanzar",
    "Hubo un error al confirmar el pago. Detalles: ":"Hubo un error al confirmar el pago. Detalles: ",
    "Hubo un error al confirmar el pago. Vuelve a intentarlo con otra tarjeta":"Hubo un error al confirmar el pago. Vuelve a intentarlo con otra tarjeta",
    "El pago aún se encuentra en estado de \"procesamiento\". Esto es inusual por favor contáctenos":"El pago aún se encuentra en estado de \"procesamiento\". Esto es inusual por favor contáctenos",
    "Lo sentimos, no tenemos productos disponibles para comprar en este momento, intenta en otra hora":"Lo sentimos, no tenemos productos disponibles para comprar en este momento, intenta en otra hora",
    "allergyText1": "Ingresa tus alergias e intolerancias alimentarias sobre como deseas recibir tus alimentos. Estos comentarios los verá el restaurante al momento de preparar tu orden.",
  "allergyHello": "Hola ",
  "profileAllergy": "Preferencias de alimentos / alergias",
  "allergyAdd": "Agregar alergia",
  "allergy": "Alergias",
  "ckeckout_allergy_tittle": "Habilita tus alergias",
  "ckeckout_allergy_descr": "¡Nuestro personal las podrá ver para que podamos cuidar de ti!",
  "edit_allergy": "Editar alergias",
  "allergy_holder": "Agrega aquí tus alergias",
  "my_allergies_list_tittle": "Mi lista de alergias activas",
  "save": "Guardar",
  "Agotado":"Agotado",
  "Restablecer instrucciones enviadas al correo electrónico ingresado":"Restablecer instrucciones enviadas al correo electrónico ingresado",
  "No puedo encontrar el correo electrónico o algo salió mal.":"No puedo encontrar el correo electrónico o algo salió mal.",
  "Por favor ingrese un correo electrónico válido primero":"Por favor ingrese un correo electrónico válido primero",
  "profileMyCard":"Mis tarjetas",
  "Enviar":"Enviar",
  "Crea tu cuenta para continuar":"Crea una cuenta para continuar",
  "Ingresa tu cuenta para continuar":"Ingresa tu cuenta para continuar",
  "Ingrese OTP una vez que reciba":"Ingrese OTP una vez que reciba",
  "Editar teléfono":"Editar teléfono",
  "cardData241": "Tarjeta con terminación ",
  "¿Olvidaste tu contraseña?":"¿Olvidaste tu contraseña?",
  "Alergias inactivas":"Alergias desactivadas para este pedido",
  "Alergias activadas":"Alergias activadas para este pedido",
  "allergyBannerTitle": "Conoce sobre las alergias",
  "allergyBannerSubTitle": "e intolerancias alimenticias",
  "allergyTerms1": "¿Qué puedo comer?",
  "allergyTerms2": "Creamos este perfil para promover una ingesta segura de nuestros usuarios que presentan a algún tipo de alergia o intolerancia alimentaria, ya que el 25% de la población del país lo padece. De esta manera pretendemos ayudarte a obtener una compra segura priorizando y resaltando en todos tus pedidos tus alergias e intolerancias alimentarias sin necesidad de ponerlos en cada una de tus órdenes. Si no conoces de este tema puedes leer hasta el final y aprender su importancia.",
  "allergyTerms3": "¿Qué es una alergia alimentaria?",
  "allergyTerms4": "La alergia a alimentos se produce cuando la persona reacciona con síntomas al contacto, ingestión o inhalación de las proteínas de un alimento provocando una reacción inmunitaria del organismo ante esa sustancia externa que entra en él y se manifiesta clínicamente a través de diferentes signos y síntomas más leves o más graves según el órgano afectado. Una intolerancia alimentaria no surge en el momentode la ingesta puede tardar horas o días y solo causara malestares, ningún riesgo fatal como los que puede causar una alergia.",
  "allergyTerms5": "¿Qué reacción puede ocasionar un alérgeno?",
  "allergyTerms6": "Las reacciones propias de las alergias alimentarias varían mucho en función de la persona afectada. Algunas de estas reacciones pueden ser muy leves y afectar sólo a una parte o sistema del organismo. Otros pueden ser más graves y afectar más de un sistema del organismo. Las reacciones pueden ocurrir durante los primeros minutos o tardar varias horas en aparecer tras la exposición al alérgeno alimentario.",
  "allergyTerms7": "La sintomatología o manifestaciones clínicas, variarán según el órgano o sistema del organismo afectado:",
  "allergyTerms8": "Sistema respiratorio: Tos persistente, silbidos, dificultad respiratoria (ahogo, dificultad para tragar, cambio de voz y rinitis.",
  "allergyTerms9": "Sistema cutáneo (frecuentes): Urticaria, ronchas (habas). Picor y opresión en el paladar y en la garganta. Inflamación labios y párpados. Conjuntivitis.",
  "allergyTerms10": "Sistema digestivo: Vómitos, náuseas, diarreas, dolor abdominal agudo.",
  "allergyTerms11": "Sistema nervioso: Pérdida del conocimiento.",
  "allergyTerms12": "Sistema cardiovascular: Mareo, hipotensión, desmayo, cianosis.",
  "allergyTerms13": "Anafilaxia: Reacción alérgica grave de instauración rápida y potencialmente mortal, después del contacto con el alérgeno, y cuando hay afectación de 2 o más órganos o sistemas del organismo.",
  "allergyTerms14": "Las consecuencias más graves son: la obstrucción de la vía aérea (edema laríngeo) y la parada cardiorrespiratoria por broncoespasmo y / o colapso vascular. Las causas más frecuentes incluyen los alimentos, los fármacos y las picaduras de insectos (abejas y avispas).",
  "allergyTerms15": "Hay más de 120 alimentos descritos como causantes de alergias alimentarias, entre los más comunes están: ",
  "allergyTerms16": "- Cereales que contienen gluten.",
  "allergyTerms17": "- Crustáceos y productos a base de crustáceos.",
  "allergyTerms18": "- Huevos y productos a base de huevo.",
  "allergyTerms19": "- Pescado y productos a base de pescado.",
  "allergyTerms20": "- Soya y productos a base de soya.",
  "allergyTerms21": "- Leche y sus derivados (incluida la lactosa).",
  "allergyTerms22": "- Frutos de secos y productos derivados. (cacahuates, nueces, etc.)",
  "allergyTerms23": "- Apio y productos derivados.",
  "allergyTerms24": "- Mostaza y productos derivados.",
  "allergyTerms25": "- Granos de sésamo.",
  "allergyTerms26": "- Dióxido de azufre y sulfito.",
  "allergyTerms27": "- Leguminosas ",
  "Bienvenido": "Bienvenido",
  "Bienvenido de regreso": "Bienvenido de regreso",
  "allergyTerms28": "- Moluscos y productos a base de moluscos.",
  "allergyTerms29": "Ahora conoces de este tema te invitamos a crear tu perfil de manera consiente y si no estas dentro de este 25% de la población a concientizarlo por si en algún momento necesitan de tu ayuda. ",
  "begin": "Comenzar",
  "No hay una ubicación de entrega seleccionada o la ubicación de entrega seleccionada no es reparable":"No hay una ubicación de entrega válida o no entregamos a la ubicación de entrega seleccionada",
  "Continuar":"Continuar",
  "Ingresar dirección":"Ingresar dirección",
  "Por favor ingresa tu dirección":"Ingresa tu dirección",
  "Añadir más productos":"Agrega más productos",
  "La compra mínima debe ser de $":"La compra mínima debe ser de $",
  "Gratis":"Gratis",
  "Creando orden, no cierre ni actualice la ventana":"Creando orden, no cierre ni actualice la ventana",
  "Buscando direcciones":"Buscando direcciones...",
  "Su pedido ha sido cancelado con éxito.":"Su pedido ha sido cancelado con éxito."
 }
});
