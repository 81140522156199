import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBCgsYnDm2Bmxwu6-_neeA1XOIoCnGLAzg",
  authDomain: "devyxyz.firebaseapp.com",
  databaseURL: "https://devyxyz.firebaseio.com",
  projectId: "devyxyz",
  storageBucket: "devyxyz.appspot.com",
  messagingSenderId: "1034333232845",
  appId: "1:1034333232845:web:6cbf27ccd19b25a6a7d384",
  measurementId: "G-3V3MMTMQ1P"
};

  export const app = initializeApp(firebaseConfig);
  export const db = getFirestore(app);
  export const auth = getAuth(app);
  export const storage = getStorage(app);
